// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsArray } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

// Imports => Molecules
import AcMultiSelect from '@molecules/ac-multi-select/ac-multi-select';

const _CLASSES = {
	MAIN: 'ac-multi-select-modal',
	WIDE: 'ac-multi-select-modal--wide',
	CONTENT: 'ac-multi-select-modal__content',
};

const AcMultiSelectModal = ({
	store: { ui },
	instance,
	collection,
	initialSelection = [],
	introduction = null,
	wide = false,
	verify_errors = false,
	multiple = true,
	cancel = {
		label: 'Cancel',
		callback: () => {},
	},
	confirm = {
		label: 'Save',
		callback: () => {},
	},
}) => {
	const [selection, setSelection] = useState(initialSelection || []);
	const [highlight, setHighlight] = useState([]);
	const [feedback, setFeedback] = useState(null);

	useEffect(() => {
		setSelection(initialSelection);
	}, [initialSelection]);

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (AcIsSet(cancel) && cancel?.callback) await cancel.callback();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (confirm && confirm.callback)
			confirm
				.callback(selection)
				.then((response) => {
					if (verify_errors) {
						if (
							AcIsSet(response) &&
							AcIsSet(response.response) &&
							AcIsSet(response.response.data)
						) {
							const { errors } = response.response.data;
							const should_highlight = [];

							for (let key in errors) {
								const arr = key.split('.');
								let index = null;
								if (arr && AcIsArray(arr)) index = arr[1];

								if (index) {
									const id = selection[index];
									if (id) should_highlight.push(id);
								}
							}

							setHighlight(should_highlight);
							setFeedback(
								'<p>Not all selected items could be processed. Please review your selection and try again.</p>'
							);
						} else {
							setSelection(initialSelection);
							setHighlight([]);
							setFeedback(null);
							ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
						}
					} else {
						setSelection(initialSelection);
						setHighlight([]);
						setFeedback(null);
						ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
					}
				})
				.catch((error) => {});
	};

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: cancel?.label || 'Cancel',
			callback: handleCancel,
		};
	}, [cancel]);

	const getConfirmButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			title: confirm.label || 'Save',
			disabled: (instance && instance.is_busy) || !selection?.length,
			callback: handleSubmit,
		};
	}, [instance, selection, confirm]);

	const getConfirmButtonLabel = useMemo(() => {
		if (!multiple) return `${confirm.label}`;
		return `${confirm.label} <sub>${selection?.length || 0}</sub>`;
	}, [instance, selection, confirm, multiple]);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN, wide && _CLASSES.WIDE]);
	}, [wide]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid>
					<AcMultiSelect
						{...{
							instance,
							collection,
							introduction,
							multiple,
							highlight,
							feedback,
							initialSelection: selection,
							onChange: setSelection,
						}}
					/>
					<AcRow className={'h-margin-top-40'}>
						<AcColumn
							xxs={12}
							xs={7}
							sm={6}
							className={'h-text--align-left h-flex-v-align-center'}
						>
							{AcIsSet(cancel) && (
								<AcButton {...getCancelButtonOptions}>
									<span
										dangerouslySetInnerHTML={{
											__html: cancel?.label,
										}}
									/>
								</AcButton>
							)}
						</AcColumn>

						<AcColumn xxs={12} xs={5} sm={6} className={'h-text--align-right'}>
							{AcIsSet(confirm) && (
								<AcButton {...getConfirmButtonOptions}>
									<span
										dangerouslySetInnerHTML={{
											__html: getConfirmButtonLabel,
										}}
									/>
								</AcButton>
							)}
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>

			{instance && instance.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcMultiSelectModal));
